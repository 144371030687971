import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/vercel/path0/packages/recipes/src/layouts/recipe.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h5>{`Prep time: 15 min. `}<meta itemProp="prepTime" content="PT15M" /></h5>
    <h5>{`Cook time: 45 min. `}<meta itemProp="cookTime" content="PT45M" /></h5>
    <h5>{`Servings: `}<span itemProp="recipeYield">{`2`}</span></h5>
    <hr></hr>
    <h2>{`Ingredients`}</h2>
    <ul>
      <li parentName="ul">
        <span itemProp="recipeIngredient">Salmon fillets, 2</span>
      </li>
      <li parentName="ul">
        <span itemProp="recipeIngredient">Mayonnaise, 1/4 cup</span>
      </li>
      <li parentName="ul">
        <span itemProp="recipeIngredient">Parmesan, 2 tbs. grated</span>
      </li>
      <li parentName="ul">
        <span itemProp="recipeIngredient">Cayenne, 1/4 tsp.</span>
      </li>
      <li parentName="ul">
        <span itemProp="recipeIngredient">Lemon, juice from half lemon</span>
      </li>
      <li parentName="ul">
        <span itemProp="recipeIngredient">
  Whole wheat Ritz crackers, ~13 crackers
        </span>
      </li>
    </ul>
    <hr></hr>
    <h2>{`Equipment`}</h2>
    <ul>
      <li parentName="ul">
        <span itemProp="tool">8x8 glass pan</span>
      </li>
      <li parentName="ul">
        <span itemProp="tool">Aluminum foil</span>
      </li>
      <li parentName="ul">
        <span itemProp="tool">Lemon squeezer</span>
      </li>
      <li parentName="ul">
        <span itemProp="tool">Grater</span>
      </li>
      <li parentName="ul">
        <span itemProp="tool">Small bowl</span>
      </li>
    </ul>
    <hr></hr>
    <h2>{`Prep`}</h2>
    <ul>
      <li parentName="ul">
        <span itemProp="step">Grate parmesan</span>
      </li>
    </ul>
    <hr></hr>
    <h2>{`Instructions`}</h2>
    <span itemProp="recipeInstructions">
      <ol>
        <li parentName="ol">
          <p parentName="li">{`Combine mayonnaise, parmesan, and cayenne in small bowl.`}</p>
        </li>
        <li parentName="ol">
          <p parentName="li">{`Line glass pan with aluminum foil.`}</p>
        </li>
        <li parentName="ol">
          <p parentName="li">{`Arrange salmon fillets in glass pan.`}</p>
        </li>
        <li parentName="ol">
          <p parentName="li">{`Squeeze lemon juice over the salmon fillets.`}</p>
        </li>
        <li parentName="ol">
          <p parentName="li">{`Cover salmon with the mayonnaise and parmesan mixture.`}</p>
        </li>
        <li parentName="ol">
          <p parentName="li">{`Top with crushed crackers.`}</p>
        </li>
        <li parentName="ol">
          <p parentName="li">{`Bake at 350F for 30 minutes, or until the crust is golden brown.`}</p>
        </li>
      </ol>
    </span>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      